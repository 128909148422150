import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";
import * as AdaptiveCards from "adaptivecards";
import { Loader, Button, Text, List, Image } from '@stardust-ui/react';
import * as microsoftTeams from "@microsoft/teams-js";

import './sendConfirmationTaskModule.scss';
import { getDraftNotification, getConsentSummaries, sendDraftNotification } from '../../apis/messageListApi';
import {
    getInitAdaptiveCard, setCardTitle, setCardImageLink, setCardSummary,
    setCardAuthor, setCardBtn, setCardVideoLink, setCardPosterLink, setCardPosterUrl, setCardPosterAction, setCardDepartment
} from '../AdaptiveCard/adaptiveCard';

import { ImageUtil } from '../../utility/imageutility';
import { TFunction } from "i18next";

export interface IListItem {
    header: string,
    media: JSX.Element,
}

export interface IMessage {
    id: string;
    title: string;
    acknowledgements?: number;
    reactions?: number;
    responses?: number;
    succeeded?: number;
    failed?: number;
    throttled?: number;
    sentDate?: string;
    imageLink?: string;
    summary?: string;
    author?: string;
    buttonLink: string;
    buttonTitle?: string;
    senderName?: string;
    departmentName?: string;
    videoUrl: string;

}

export interface SendConfirmationTaskModuleProps extends RouteComponentProps, WithTranslation {
}

export interface IStatusState {
    message: IMessage;
    loader: boolean;
    teamNames: string[];
    rosterNames: string[];
    groupNames: string[];
    allUsers: boolean;
    messageId: number;
    selectedTemplate: number;
}

class SendConfirmationTaskModule extends React.Component<SendConfirmationTaskModuleProps, IStatusState> {
    readonly localize: TFunction;
    private initMessage = {
        id: "",
        title: "",
        videoUrl: "",
        buttonLink: "",
        departmentName:"",
    };

    private card: any;

    constructor(props: SendConfirmationTaskModuleProps) {
        super(props);
        this.localize = this.props.t;
        this.card = getInitAdaptiveCard(this.localize,0);

        this.state = {
            message: this.initMessage,
            loader: true,
            teamNames: [],
            rosterNames: [],
            groupNames: [],
            allUsers: false,
            messageId: 0,
            selectedTemplate: 0,
            
        };
    }

    public componentDidMount() {
        microsoftTeams.initialize();

        let params = this.props.match.params;
        
        if ('id' in params) {
            let id = params['id'];
            this.getItem(id).then(() => {
                getConsentSummaries(id).then((response) => {
                    this.setState({
                        teamNames: response.data.teamNames.sort(),
                        rosterNames: response.data.rosterNames.sort(),
                        groupNames: response.data.groupNames.sort(),
                        allUsers: response.data.allUsers,
                        messageId: id,
                        selectedTemplate: response.data.selectedTemplate,
                        
                    }, () => {
                            console.log("Response data:", response.data);
                        this.setState({
                            loader: false
                        }, () => {
                                console.log("Send Confirmation template:", this.state.selectedTemplate );
                                if (response.data.selectedTemplate === 0) {
                                    this.card = getInitAdaptiveCard(this.localize, 0);
                                    setCardTitle(this.card, this.state.message.title);
                                    setCardImageLink(this.card, this.state.message.imageLink);
                                    setCardSummary(this.card, this.state.message.summary);
                                    setCardAuthor(this.card, this.state.message.author);
                                    setCardDepartment(this.card, this.state.message.departmentName);
                                    if (this.state.message.buttonTitle && this.state.message.buttonLink) {
                                        setCardBtn(this.card, this.state.message.buttonTitle, this.state.message.buttonLink);
                                    }
                                }
                                else
                                    if (response.data.selectedTemplate === 1) {
                                        this.card = getInitAdaptiveCard(this.localize, 4);
                                        setCardTitle(this.card, this.state.message.title);
                                        setCardImageLink(this.card, this.state.message.imageLink);
                                        setCardSummary(this.card, this.state.message.summary);
                                        setCardAuthor(this.card, this.state.message.author);
                                        setCardDepartment(this.card, this.state.message.departmentName);
                                        if (this.state.message.buttonTitle && this.state.message.buttonLink) {
                                            setCardBtn(this.card, this.state.message.buttonTitle, this.state.message.buttonLink);
                                        }
                                    }
                                    else
                                    if (response.data.selectedTemplate === 2) {
                                        this.card = getInitAdaptiveCard(this.localize, 1);
                                        setCardTitle(this.card, this.state.message.title);
                                        setCardVideoLink(this.card, this.state.message.videoUrl);
                                        setCardPosterLink(this.card, this.state.message.imageLink);
                                        setCardSummary(this.card, this.state.message.summary);
                                        setCardAuthor(this.card, this.state.message.author);
                                        setCardDepartment(this.card, this.state.message.departmentName);
                                    }
                                    else
                                        if (response.data.selectedTemplate === 3) {
                                            this.card = getInitAdaptiveCard(this.localize, 5);
                                            setCardTitle(this.card, this.state.message.title);
                                            setCardVideoLink(this.card, this.state.message.videoUrl);
                                            setCardPosterLink(this.card, this.state.message.imageLink);
                                            setCardSummary(this.card, this.state.message.summary);
                                            setCardAuthor(this.card, this.state.message.author);
                                            setCardDepartment(this.card, this.state.message.departmentName);
                                        }
                                        else
                                        if (response.data.selectedTemplate === 4) {
                                            this.card = getInitAdaptiveCard(this.localize, 2);
                                            setCardPosterUrl(this.card, this.state.message.imageLink);
                                            setCardPosterAction(this.card, this.state.message.buttonLink);
                                        }
                            console.log("Confirmation Page Card ", this.card)

                            let adaptiveCard = new AdaptiveCards.AdaptiveCard();
                            adaptiveCard.parse(this.card);
                            let renderedCard = adaptiveCard.render();
                            document.getElementsByClassName('adaptiveCardContainer')[0].appendChild(renderedCard!);
                            if (this.state.message.buttonLink) {
                                let link = this.state.message.buttonLink;
                                adaptiveCard.onExecuteAction = function (action) { window.open(link, '_blank'); };
                            }
                        });
                    });
                });
            });
        }
    }

    private getItem = async (id: number) => {
        try {
            const response = await getDraftNotification(id);
            this.setState({
                message: response.data
            });
        } catch (error) {
            return error;
        }
    }

    public render(): JSX.Element {
        if (this.state.loader) {
            return (
                <div className="Loader">
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className="taskModule">
                    <div className="formContainer">
                        <div className="formContentContainer" >
                            <div className="contentField">
                                <h3>{this.localize("ConfirmToSend")}</h3>
                                <span>{this.localize("SendToRecipientsLabel")}</span>
                            </div>

                            <div className="results">
                                {this.renderAudienceSelection()}
                            </div>
                        </div>
                        <div className="adaptiveCardContainer">
                        </div>
                    </div>

                    <div className="footerContainer">
                        <div className="buttonContainer">
                            <Loader id="sendingLoader" className="hiddenLoader sendingLoader" size="smallest" label={this.localize("PreparingMessageLabel")} labelPosition="end" />
                            <Button content={this.localize("Send")} id="sendBtn" onClick={this.onSendMessage} primary />
                        </div>
                    </div>
                </div>
            );
        }
    }

    private onSendMessage = () => {
        let spanner = document.getElementsByClassName("sendingLoader");
        spanner[0].classList.remove("hiddenLoader");
        console.log("Send Button Message", this.state.message)
        sendDraftNotification(this.state.message).then(() => {
            microsoftTeams.tasks.submitTask();
        });
    }

    private getItemList = (items: string[]) => {
        let resultedTeams: IListItem[] = [];
        if (items) {
            resultedTeams = items.map((element) => {
                const resultedTeam: IListItem = {
                    header: element,
                    media: <Image src={ImageUtil.makeInitialImage(element)} avatar />
                }
                return resultedTeam;
            });
        }
        return resultedTeams;
    }

    private renderAudienceSelection = () => {
        if (this.state.teamNames && this.state.teamNames.length > 0) {
            return (
                <div key="teamNames"> <span className="label">{this.localize("TeamsLabel")}</span>
                    <List items={this.getItemList(this.state.teamNames)} />
                </div>
            );
        } else if (this.state.rosterNames && this.state.rosterNames.length > 0) {
            return (
                <div key="rosterNames"> <span className="label">{this.localize("TeamsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.rosterNames)} />
                </div>);
        } else if (this.state.groupNames && this.state.groupNames.length > 0) {
            return (
                <div key="groupNames" > <span className="label">{this.localize("GroupsMembersLabel")}</span>
                    <List items={this.getItemList(this.state.groupNames)} />
                </div>);
        } else if (this.state.allUsers) {
            return (
                <div key="allUsers">
                    <span className="label">{this.localize("AllUsersLabel")}</span>
                    <div className="noteText">
                        <Text error content={this.localize("SendToAllUsersNote")} />
                    </div>
                </div>);
        } else {
            return (<div></div>);
        }
    }
}

const sendConfirmationTaskModuleWithTranslation = withTranslation()(SendConfirmationTaskModule);
export default sendConfirmationTaskModuleWithTranslation;